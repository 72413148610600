type ImageToCID = {
  id: string;
  base64encoded?: boolean;
  data: string;
};

type ImageToCIDResponse = {
  id: string;
  cid: string;
};

export async function imagesToCIDs(
  assets: ImageToCID[]
): Promise<ImageToCIDResponse[]> {
  const reqBody = {
    assets: assets.map(a => {
      return {
        id: a.id,
        base64encoded: a.base64encoded || false,
        data: a.data,
      };
    }),
  };

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_EVENT_ROLLUP_URI}/add-to-ipfs`,
    {
      method: 'POST',
      body: JSON.stringify(reqBody),
    }
  ).catch(e => {
    throw new Error(e);
  });

  const json = await response.json();

  if (!json.success) {
    throw new Error(json.error);
  }
  return json.payload;
}
