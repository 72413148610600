import React, {Suspense} from 'react';
import styles from '@/view/styles/components/ProfileHeader/blocs/ProfileActions/blocs/ProfileHeaderActionsUserDetails.module.scss';
import {ConnectionsDialog} from '@/view/components/CMDk/Connections/ConnectionsDialog';
import Router from 'next/router';
import Skeleton from '@/view/components/Skeleton';
import {profileFragment$key} from '@/graphql/__generated__/profileFragment.graphql';
import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import profileFragment from '@/graphql/profile-fragment';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {ProfileHeaderActionsUserDetailsConnectionsCountQuery as ProfileHeaderActionsUserDetailsConnectionsCountQueryType} from './__generated__/ProfileHeaderActionsUserDetailsConnectionsCountQuery.graphql';
import TextClamp from '@/view/components/TextClamp';
import {ProfileHeaderActionsUserDetailsFragment$key} from './__generated__/ProfileHeaderActionsUserDetailsFragment.graphql';

const ProfileHeaderActionsUserDetailsFragment = graphql`
  fragment ProfileHeaderActionsUserDetailsFragment on profiles {
    bio
    tezos_profilesCollection {
      edges {
        node {
          description
        }
      }
    }
  }
`;

export default function ProfileHeaderActionsUserDetails({
  userProfileKey,
}: {
  userProfileKey:
    | ProfileHeaderActionsUserDetailsFragment$key
    | undefined
    | null;
}) {
  const userProfile = useFragment(
    ProfileHeaderActionsUserDetailsFragment,
    userProfileKey
  );
  return (
    (userProfile?.bio ||
      userProfile?.tezos_profilesCollection?.edges?.[0]?.node?.description) && (
      <div className={styles.details}>
        <Suspense
          fallback={
            <div className={styles.skeleton}>
              <Skeleton
                variant="text"
                animation="wave"
                height={14}
                width={'100%'}
              />
              <Skeleton
                variant="text"
                animation="wave"
                height={14}
                width={'100%'}
              />
              <Skeleton
                variant="text"
                animation="wave"
                height={14}
                width={'60%'}
              />
            </div>
          }
        >
          <Description
            bio={
              userProfile?.bio ||
              userProfile?.tezos_profilesCollection?.edges?.[0]?.node
                ?.description ||
              ''
            }
          />
        </Suspense>
      </div>
    )
  );
}

export const ProfileHeaderActionsUserDetailsSocials = ({
  userId,
}: {
  userId: string;
}) => {
  return (
    <Suspense
      fallback={
        <div className={styles.skeleton}>
          <Skeleton
            variant="text"
            animation="wave"
            height={14}
            width={'100px'}
          />
          <Skeleton
            variant="text"
            animation="wave"
            height={14}
            width={'100px'}
          />
        </div>
      }
    >
      <ConnectionsSection id={userId} />
    </Suspense>
  );
};

const Description = ({bio}: {bio: string | null}) => {
  return (
    bio && (
      <TextClamp
        className={styles.description}
        text={bio}
        maxChars={200}
        prefix={null}
        enableHashtags
        enableUrls
        enableMentions
      />
    )
  );
};

const ProfileHeaderActionsUserDetailsConnectionsCountQuery = graphql`
  query ProfileHeaderActionsUserDetailsConnectionsCountQuery($id: BigInt!) {
    followers: followsCollection(filter: {followee_id: {eq: $id}}) {
      totalCount
    }
    following: followsCollection(filter: {follower_id: {eq: $id}}) {
      totalCount
    }
    ...ConnectionsDialogFragment
  }
`;

const ConnectionsSection = ({id}: {id: string}) => {
  const {isMobile} = useBreakpoint();

  const {data} =
    useLazyLoadQuery<ProfileHeaderActionsUserDetailsConnectionsCountQueryType>(
      ProfileHeaderActionsUserDetailsConnectionsCountQuery,
      {
        id: id,
      },
      {
        skip: !id || id === '',
      }
    );

  return (
    <div className={styles.stats}>
      <ConnectionsDialog
        id={id}
        connectionsCountKey={data}
        trigger={
          <div
            onClick={() => {
              if (isMobile) {
                Router.push(
                  {pathname: Router.pathname, query: {...Router.query}},
                  Router.asPath + '#',
                  {
                    shallow: true,
                    scroll: false,
                  }
                );
              }
            }}
          >
            <span>{data?.followers?.totalCount || 0}</span> Followers
          </div>
        }
        defaultTab={0}
      />
      <ConnectionsDialog
        id={id}
        connectionsCountKey={data}
        trigger={
          <div
            onClick={() => {
              if (isMobile) {
                Router.push(
                  {pathname: Router.pathname, query: {...Router.query}},
                  Router.asPath + '#',
                  {
                    shallow: true,
                    scroll: false,
                  }
                );
              }
            }}
          >
            <span>{data?.following?.totalCount || 0}</span> Following
          </div>
        }
        defaultTab={1}
      />
    </div>
  );
};
