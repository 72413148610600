import React from 'react';
import styles from '@/view/styles/components/ProfilePictureView.module.scss';
import {Avatar} from './Avatar';
import {MemoIcon} from './utils/MemoChildren';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';

export default function ProfilePictureView({
  avatar,
  closeDialog,
}: {
  avatar: string;
  closeDialog: (isPopState: boolean) => void;
}) {
  React.useEffect(() => {
    const handlePopState = () => {
      closeDialog?.(true);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [closeDialog]);

  const {isMobile} = useBreakpoint();

  return (
    <div className={styles.root} onClick={closeDialog.bind(null, false)}>
      <Avatar
        src={avatar}
        alt=""
        key={'avatar'}
        className={styles.avatar}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <MemoIcon Component={'user-line'} size={isMobile ? 42 : 84} />
      </Avatar>
    </div>
  );
}
