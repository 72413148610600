import React, {Suspense} from 'react';
import styles from '@/view/styles/pages/profile/blocs/LibraryTab/blocs/LibraryTabContent.module.scss';
import {
  commitLocalStoreUpdate,
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePagination,
} from '@/kits/relay-kit/src';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import {useCreateStatus} from '@/state/hooks/createStatus';
import {useOnchainActionStatus} from '@/state/hooks/onChainActionStatus';
import clsx from 'clsx';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import Skeleton from '@/view/components/Skeleton';
import PostCard from '@/view/components/PostCard/PostCard';
import PlaylistCardDefault from '@/view/components/PlaylistCard/PlaylistCardDefault';
import {useAccount} from '@/kits/account-kit/src';
import type {LibraryTabContentGetAccountIdQuery as LibraryTabContentGetAccountIdQueryType} from './__generated__/LibraryTabContentGetAccountIdQuery.graphql';
import type {LibraryTabContentOwnedTokensQuery as LibraryTabContentOwnedTokensQueryType} from './__generated__/LibraryTabContentOwnedTokensQuery.graphql';
import {LibraryTabContentOwnedTokensPaginated$key} from './__generated__/LibraryTabContentOwnedTokensPaginated.graphql';
import {LibraryTabContentOwnedTokensPaginatedQuery} from './__generated__/LibraryTabContentOwnedTokensPaginatedQuery.graphql';
import {LibraryTabContentPostCardWrapperFragment$key} from './__generated__/LibraryTabContentPostCardWrapperFragment.graphql';
import {LibraryTabContentPlaylistsPaginated$key} from './__generated__/LibraryTabContentPlaylistsPaginated.graphql';
import {LibraryTabContentCollabPlaylistsPaginated$key} from './__generated__/LibraryTabContentCollabPlaylistsPaginated.graphql';
import {LibraryTabContentPlaylistsPaginatedQuery} from './__generated__/LibraryTabContentPlaylistsPaginatedQuery.graphql';
import {LibraryTabContentCollabPlaylistsPaginatedQuery} from './__generated__/LibraryTabContentCollabPlaylistsPaginatedQuery.graphql';
import type {LibraryTabContentPlaylistsQuery as LibraryTabContentPlaylistsQueryType} from './__generated__/LibraryTabContentPlaylistsQuery.graphql';
import type {LibraryTabContentCollabPlaylistsQuery as LibraryTabContentCollabPlaylistsQueryType} from './__generated__/LibraryTabContentCollabPlaylistsQuery.graphql';

const LibraryTabContentGetAccountIdQuery = graphql`
  query LibraryTabContentGetAccountIdQuery($identityId: BigInt!) {
    identitiesCollection(filter: {id: {eq: $identityId}}) {
      edges {
        node {
          id
          nodeId
          accountsCollection {
            edges {
              node {
                id
                nodeId
              }
            }
          }
        }
      }
    }
  }
`;

const LibraryTabContentOwnedTokensQuery = graphql`
  query LibraryTabContentOwnedTokensQuery($account_id: BigInt!) {
    ...LibraryTabContentOwnedTokensPaginated @arguments(account_id: $account_id)
  }
`;

const LibraryTabContentPlaylistsQuery = graphql`
  query LibraryTabContentPlaylistsQuery($identityIds: [BigInt!]) {
    ...LibraryTabContentPlaylistsPaginated @arguments(identityIds: $identityIds)
  }
`;

const LibraryTabContentCollabPlaylistsQuery = graphql`
  query LibraryTabContentCollabPlaylistsQuery($accountIds: [BigInt!]) {
    ...LibraryTabContentCollabPlaylistsPaginated
      @arguments(accountIds: $accountIds)
  }
`;

export default function LibraryTabContent({
  identityId,
  activeTab,
}: {
  identityId: string;
  activeTab: number;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();

  const {data: accountQueryData} =
    useLazyLoadQuery<LibraryTabContentGetAccountIdQueryType>(
      LibraryTabContentGetAccountIdQuery,
      {
        identityId: identityId || '',
      },
      {
        skip: !identityId || identityId === '',
      }
    );

  const accountId =
    accountQueryData?.identitiesCollection?.edges?.[0]?.node?.accountsCollection
      ?.edges?.[0]?.node?.id;

  const {status: createStatus} = useCreateStatus();
  const {actions} = useOnchainActionStatus();

  React.useEffect(() => {
    if (createStatus === 'done' || actions.length > 0) {
      const timeout = setTimeout(() => {
        commitLocalStoreUpdate(updater => {
          updater
            .get(
              `client:root:eventsCollection(filter:{"type":{"neq":"follow"}},first:10,orderBy:{"created_at":"DescNullsLast"})`
            )
            ?.invalidateRecord();
          updater
            .get(
              `client:root:__HomeTabFilteredFeedEventsPaginated_get_filtered_timeline_events_connection(orderBy:{"created_at":"DescNullsLast"},type:"image")`
            )
            ?.invalidateRecord();
          updater
            .get(
              `client:root:__HomeTabFilteredFeedEventsPaginated_get_filtered_timeline_events_connection(orderBy:{"created_at":"DescNullsLast"},type:"post")`
            )
            ?.invalidateRecord();
          updater
            .get(
              `client:root:__HomeTabFilteredFeedEventsPaginated_get_filtered_timeline_events_connection(orderBy:{"created_at":"DescNullsLast"},type:"audio")`
            )
            ?.invalidateRecord();
          updater
            .get(
              `client:root:__HomeTabFilteredFeedEventsPaginated_get_filtered_timeline_events_connection(orderBy:{"created_at":"DescNullsLast"},type:"video")`
            )
            ?.invalidateRecord();
          updater
            .get(
              `client:root:eventsCollection(filter:{"account_id":{"eq":"${accountId}"},"type":{"neq":"follow"}},first:5,orderBy:{"created_at":"DescNullsLast"})`
            )
            ?.invalidateRecord();
        });
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [createStatus, actions, accountId, identityId]);

  const {data} = useLazyLoadQuery<LibraryTabContentOwnedTokensQueryType>(
    LibraryTabContentOwnedTokensQuery,
    {
      account_id: accountId || '',
    },
    {
      skip: !accountId || accountId === '',
    }
  );

  const {data: playlists} =
    useLazyLoadQuery<LibraryTabContentPlaylistsQueryType>(
      LibraryTabContentPlaylistsQuery,
      {
        identityIds: [identityId || ''],
      },
      {
        skip: !identityId || identityId === '',
      }
    );

  const {data: collabPlaylists} =
    useLazyLoadQuery<LibraryTabContentCollabPlaylistsQueryType>(
      LibraryTabContentCollabPlaylistsQuery,
      {
        accountIds: [accountId || ''],
      },
      {
        skip: !accountId || accountId === '',
      }
    );
  return (
    <div className={styles.page}>
      {activeTab < 5 && (
        <div className={styles.tabPanel}>
          <Posts tokensKey={data} tab={activeTab} />
        </div>
      )}
      {activeTab === 5 && (
        <div className={clsx(styles.tabPanel, styles.playlistsTabPanel)}>
          <Playlists
            playlistsKey={playlists}
            collabPlaylistsKey={collabPlaylists}
          />
        </div>
      )}
    </div>
  );
}

const LibraryTabContentOwnedTokensPaginated = graphql`
  fragment LibraryTabContentOwnedTokensPaginated on Query
  @argumentDefinitions(
    account_id: {type: "BigInt!"}
    first: {type: "Int", defaultValue: 30}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "LibraryTabContentOwnedTokensPaginatedQuery") {
    get_owned_tokens_for_account(
      account_id: $account_id
      orderBy: {created_at: DescNullsLast}
      first: $first
      after: $after
    )
      @connection(
        key: "LibraryTabContentOwnedTokensPaginatedQuery_get_owned_tokens_for_account"
      ) {
      edges {
        node {
          nodeId
          id
          type
          ownedCount: eventsCollection(
            filter: {
              and: {
                type: {
                  in: [
                    mint_tezos_token
                    pending_mint_tezos_token
                    purchase_tezos_token
                    pending_purchase_tezos_token
                    collect_for_free
                    mint_for_free
                  ]
                }
                account_id: {eq: $account_id}
              }
            }
          ) {
            totalCount
          }
          ...LibraryTabContentPostCardWrapperFragment
        }
      }
    }
  }
`;

const Posts = ({
  tokensKey,
  tab,
}: {
  tokensKey: LibraryTabContentOwnedTokensPaginated$key | null | undefined;
  tab: number;
}) => {
  const {
    data: paginatedEvents,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
  } = usePagination<
    LibraryTabContentOwnedTokensPaginatedQuery,
    LibraryTabContentOwnedTokensPaginated$key
  >(LibraryTabContentOwnedTokensPaginated, tokensKey);

  const filteredTokens =
    paginatedEvents?.get_owned_tokens_for_account?.edges
      ?.filter(p => {
        switch (tab) {
          case 0:
            return true;
          case 1:
            return p.node?.type === 'image';
          case 2:
            return p.node?.type === 'audio';
          case 3:
            return p.node?.type === 'video';
          case 4:
            return p.node?.type === 'post';
          default:
            return true;
        }
      })
      .map(p => p.node) || [];
  return (
    <>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                loadNext(20);
              }
            : undefined
        }
        styleOverrides={{
          marginTop: '-20vh',
          width: '100%',
          height: '100px',
        }}
      >
        {filteredTokens.length > 0 ? (
          filteredTokens?.map((ev, i) => {
            return (
              ev && (
                <Suspense key={ev.id + i}>
                  <PostCardWrapper
                    tokenKey={ev}
                    i={i}
                    ownedCount={ev.ownedCount?.totalCount || 0}
                  />
                </Suspense>
              )
            );
          })
        ) : (
          <EmptyProfileCard
            title="No items found"
            className={styles.empty}
            small
          />
        )}
      </InfiniteScrollContainer>
      {(isLoadingNext || isLoading) && (
        <>
          <Skeleton
            variant="rect"
            animation="wave"
            styles={{
              height: 0,
              paddingBottom: 'calc(100% + 82px)',
            }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            styles={{
              height: 0,
              paddingBottom: 'calc(100% + 82px)',
            }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            styles={{
              height: 0,
              paddingBottom: 'calc(100% + 82px)',
            }}
          />
        </>
      )}
    </>
  );
};

const LibraryTabContentPostCardWrapperFragment = graphql`
  fragment LibraryTabContentPostCardWrapperFragment on tezos_tokens {
    id
    nodeId
    ...PostCardTokenFragment @arguments(includeDefault: true)
  }
`;

const PostCardWrapper = ({
  tokenKey,
  i,
  ownedCount,
}: {
  tokenKey: LibraryTabContentPostCardWrapperFragment$key;
  i: number;
  ownedCount: number;
}) => {
  const token = useFragment(LibraryTabContentPostCardWrapperFragment, tokenKey);
  return (
    token && (
      <PostCard
        tokenKey={token}
        key={token.id + i + token.id}
        view={'default-library'}
        ownedCount={ownedCount}
      />
    )
  );
};

const LibraryTabContentPlaylistsPaginated = graphql`
  fragment LibraryTabContentPlaylistsPaginated on Query
  @argumentDefinitions(
    identityIds: {type: "[BigInt!]"}
    first: {type: "Int", defaultValue: 20}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "LibraryTabContentPlaylistsPaginatedQuery") {
    playlistsCollection(
      filter: {identity_id: {in: $identityIds}}
      first: $first
      after: $after
    )
      @connection(
        key: "LibraryTabContentPlaylistsPaginated_playlistsCollection"
      ) {
      edges {
        node {
          id
          nodeId
          ...PlaylistCardDefaultFragment
        }
      }
    }
  }
`;

const LibraryTabContentCollabPlaylistsPaginated = graphql`
  fragment LibraryTabContentCollabPlaylistsPaginated on Query
  @argumentDefinitions(
    accountIds: {type: "[BigInt!]"}
    first: {type: "Int", defaultValue: 20}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "LibraryTabContentCollabPlaylistsPaginatedQuery") {
    playlists_accountsCollection(
      filter: {account_id: {in: $accountIds}}
      first: $first
      after: $after
    )
      @connection(
        key: "LibraryTabContentCollabPlaylistsPaginated_playlists_accountsCollection"
      ) {
      edges {
        node {
          id
          nodeId
          playlists {
            id
            nodeId
            ...PlaylistCardDefaultFragment
          }
        }
      }
    }
  }
`;

const Playlists = ({
  playlistsKey,
  collabPlaylistsKey,
}: {
  playlistsKey: LibraryTabContentPlaylistsPaginated$key | null | undefined;
  collabPlaylistsKey:
    | LibraryTabContentCollabPlaylistsPaginated$key
    | null
    | undefined;
}) => {
  const {
    data: paginatedPlaylists,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
  } = usePagination<
    LibraryTabContentPlaylistsPaginatedQuery,
    LibraryTabContentPlaylistsPaginated$key
  >(LibraryTabContentPlaylistsPaginated, playlistsKey);
  const {
    data: paginatedCollabPlaylists,
    hasNext: collabHasNext,
    loadNext: collabLoadNext,
    isLoadingNext: collabIsLoadingNext,
    isLoading: collabIsLoading,
  } = usePagination<
    LibraryTabContentCollabPlaylistsPaginatedQuery,
    LibraryTabContentCollabPlaylistsPaginated$key
  >(LibraryTabContentCollabPlaylistsPaginated, collabPlaylistsKey);
  return (
    <>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                loadNext(20);
              }
            : undefined
        }
        styleOverrides={{
          width: '100%',
          height: '100%',
          gridColumn: '1 / -1',
        }}
      >
        {paginatedPlaylists?.playlistsCollection?.edges.map((list, i) => {
          return (
            <PlaylistCardDefault
              listKey={list.node}
              key={list.node.id}
              view={'mini'}
            />
          );
        })}
      </InfiniteScrollContainer>
      <InfiniteScrollContainer
        loadMore={
          collabHasNext && !collabIsLoadingNext && !collabIsLoading
            ? () => {
                collabLoadNext(20);
              }
            : undefined
        }
        styleOverrides={{
          width: '100%',
          height: '100%',
          gridColumn: '1 / -1',
        }}
      >
        {paginatedCollabPlaylists?.playlists_accountsCollection?.edges.map(
          (account, i) => {
            return (
              <PlaylistCardDefault
                listKey={account.node.playlists}
                key={account.node.playlists.id}
                view={'mini'}
              />
            );
          }
        )}
      </InfiniteScrollContainer>
      {(isLoadingNext ||
        isLoading ||
        collabIsLoadingNext ||
        collabIsLoading) && (
        <>
          <Skeleton
            variant="rect"
            animation="wave"
            styles={{
              height: 0,
              paddingBottom: 'calc(100% + 82px)',
            }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            styles={{
              height: 0,
              paddingBottom: 'calc(100% + 82px)',
            }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            styles={{
              height: 0,
              paddingBottom: 'calc(100% + 82px)',
            }}
          />
        </>
      )}
    </>
  );
};
