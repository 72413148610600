import React, {Suspense} from 'react';
import styles from '@/view/styles/pages/profile/Profile.module.scss';
import TabPanel from '@/view/components/TabPanel';
import TopBar from '@/view/components/TopBar/TopBar';
import ProfileTab from '@/view/pages/profile/blocs/ProfileTab/ProfileTab';
import LibraryTab from '@/view/pages/profile/blocs/LibraryTab/LibraryTab';
import {useRouter} from 'next/router';
import DesktopTopBar from '@/view/components/TopBar/DesktopTopBar';
import ProfileHeader from '@/view/components/ProfileHeader/ProfileHeader';
import {useAccount} from '@/kits/account-kit/src';
import Skeleton from '@/view/components/Skeleton';
import Head from 'next/head';
import {PRIMARY_DOMAIN} from '@/config';
import {generateMetaTags} from '@/utils/generateMetaTags';

export default function Profile({
  props,
}: {
  props: {
    id?: string;
    title?: string | null;
    description?: string | null;
    image?: string | null;
  };
}) {
  const {getAccount} = useAccount();
  const account = getAccount();
  const currentIdentityId = account.isLoggedIn ? account.identityId : '';
  const isOwnProfile = currentIdentityId === props.id;
  const identityId = isOwnProfile ? currentIdentityId : props.id;
  const router = useRouter();

  const isLibraryTab = router.asPath?.includes('#library');

  const [tab, setTab] = React.useState<number>(() => (isLibraryTab ? 1 : 0));

  React.useEffect(() => {
    const onBeforeUnload = () => {
      sessionStorage.setItem('profileLastFilter', tab.toString());
      sessionStorage.setItem(
        'profileScrollPosition',
        (window.scrollY || document.documentElement.scrollTop || 0).toString()
      );
    };
    router.events.on('routeChangeStart', onBeforeUnload); // this runs on every route change
    window.addEventListener('beforeunload', onBeforeUnload); // this runs on page refresh or tab close
    return () => {
      router.events.off('routeChangeStart', onBeforeUnload);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [tab]);

  React.useEffect(() => {
    const scrollPosition = sessionStorage.getItem('profileScrollPosition');
    const lastTab = sessionStorage.getItem('profileLastFilter');
    if (lastTab !== null) {
      setTab(isLibraryTab ? 1 : Number(lastTab));
    }
    if (scrollPosition !== null) {
      window.scrollTo(0, Number(scrollPosition));
    }
  }, []);

  return (
    <>
      <Head>
        <title key="title">
          {props?.title ? `DNS.xyz | ${props.title}` : undefined}
        </title>
        {Array.from(
          Object.entries(
            generateMetaTags({
              id: props.id,
              title: props.title || undefined,
              description: props.description || undefined,
              ogType: 'profile:username',
              audio: undefined,
              video: undefined,
              image: props.image || undefined,
              mediaType: undefined,
              url: `https://${PRIMARY_DOMAIN}/${
                props.title ? props.title : props.id
              }`,
            })
          )
        )?.map(tag => (
          <meta
            name={tag[0]}
            property={tag[0]}
            content={tag[1]}
            key={tag[0]?.replace(':', '-')}
          />
        ))}
      </Head>
      <>
        <TopBar />
        <DesktopTopBar />
        <div className={styles.root}>
          <Suspense
            fallback={<Skeleton variant="rect" animation="wave" height={480} />}
          >
            <ProfileHeader
              isOwnProfile={isOwnProfile}
              loggedIn={account.isLoggedIn}
              profileWallet={props.id || ''}
              tab={tab}
              setTab={t => {
                setTab(t);
              }}
            />
          </Suspense>
          <div>
            <TabPanel show={0} tab={tab} className={styles.tabPanel}>
              <ProfileTab id={identityId || ''} />
            </TabPanel>
            <TabPanel show={1} tab={tab} className={styles.tabPanel}>
              <LibraryTab identityId={props.id || ''} />
            </TabPanel>
          </div>
        </div>
      </>
    </>
  );
}
