/**
 * @generated SignedSource<<166749d8daee620562bcc15f833cff1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectionsDialogFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConnectionsFragment">;
  readonly " $fragmentType": "ConnectionsDialogFragment";
};
export type ConnectionsDialogFragment$key = {
  readonly " $data"?: ConnectionsDialogFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectionsDialogFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectionsDialogFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectionsFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "fd24c1957e0689210299763e6fb4b75d";

export default node;
