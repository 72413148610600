/**
 * @generated SignedSource<<5b828dda8c6cc78caa2f62a8f177c92d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_account_type = "beacon" | "magic" | "teztok" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileActionsFragment$data = {
  readonly id: string;
  readonly identities: {
    readonly accountsCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly beacon_accountsCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly nodeId: string;
                readonly public_key_hash: string;
              };
            }>;
          } | null;
          readonly id: string;
          readonly magic_accountsCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly nodeId: string;
                readonly public_key_hash: string;
              };
            }>;
          } | null;
          readonly nodeId: string;
          readonly teztok_accountsCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly nodeId: string;
                readonly public_key_hash: string;
              };
            }>;
          } | null;
          readonly type: enum_account_type;
        };
      }>;
    } | null;
  };
  readonly nodeId: string;
  readonly website: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileHeaderActionsUserDetailsFragment" | "ProfileHeaderActionsUsernameFragment">;
  readonly " $fragmentType": "ProfileActionsFragment";
};
export type ProfileActionsFragment$key = {
  readonly " $data"?: ProfileActionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileActionsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key_hash",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileActionsFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "identities",
      "kind": "LinkedField",
      "name": "identities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "accountsConnection",
          "kind": "LinkedField",
          "name": "accountsCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "accountsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "accounts",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "magic_accountsConnection",
                      "kind": "LinkedField",
                      "name": "magic_accountsCollection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "magic_accountsEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "magic_accounts",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "beacon_accountsConnection",
                      "kind": "LinkedField",
                      "name": "beacon_accountsCollection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "beacon_accountsEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "beacon_accounts",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "teztok_accountsConnection",
                      "kind": "LinkedField",
                      "name": "teztok_accountsCollection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "teztok_accountsEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "teztok_accounts",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileHeaderActionsUsernameFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileHeaderActionsUserDetailsFragment"
    }
  ],
  "type": "profiles",
  "abstractKey": null
};
})();

(node as any).hash = "7b8bfbdee0af204262d615bdda996adf";

export default node;
