import React, {Suspense} from 'react';
import styles from '@/view/styles/pages/profile/blocs/ProfileTab/ProfileTab.module.scss';
import UserTimelineFeed from '@/view/pages/profile/blocs/ProfileTab/blocs/UserTimelineFeed';
import Skeleton from '@/view/components/Skeleton';

export default function ProfileTab({id}: {id: string}) {
  return (
    <div className={styles.root}>
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '600px',
              gap: '32px',
            }}
          >
            <Skeleton
              variant="rect"
              animation="wave"
              height={720}
              borderRadius={10}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              height={720}
              borderRadius={10}
            />
          </div>
        }
      >
        <UserTimelineFeed id={id} />
      </Suspense>
    </div>
  );
}
