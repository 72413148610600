import {TabType} from '@/view/types/types';
import React, {Suspense} from 'react';
import styles from '@/view/styles/pages/profile/blocs/LibraryTab/LibraryTab.module.scss';
import Tabs from '@/view/components/Tabs';
import RemixIcon from '@/view/components/RemixIcon';
import LibraryTabContent from './blocs/LibraryTabContent';
import Skeleton from '@/view/components/Skeleton';

const tabs: TabType[] = [
  {
    label: <span>All</span>,
    value: 'all',
  },
  {
    label: (
      <span>
        <RemixIcon icon="artboard-line" size={18} />
        <span>Art</span>
      </span>
    ),
    value: 'artwork',
  },
  {
    label: (
      <span>
        <RemixIcon icon="music-2-line" size={18} />
        <span>Music</span>
      </span>
    ),
    value: 'music',
  },
  {
    label: (
      <span>
        <RemixIcon icon="video-line" size={18} />
        <span>Video</span>
      </span>
    ),
    value: 'video',
  },
  {
    label: (
      <span>
        <RemixIcon icon="article-line" size={18} />
        <span>Posts</span>
      </span>
    ),
    value: 'posts',
  },
  {
    label: (
      <span>
        <RemixIcon icon="list-unordered" size={18} />
        <span>Collections</span>
      </span>
    ),
    value: 'playlists',
  },
];

export default function LibraryTab({identityId}: {identityId: string}) {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div className={styles.root}>
      <div className={styles.tabs}>
        <Tabs
          tabs={tabs}
          tab={activeTab}
          setTab={setActiveTab}
          variant="chips"
        />
      </div>
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: 'var(--gap-medium-plus, 16px)',
              flex: '1 0',
              alignSelf: 'stretch',
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(188px, 1fr))',
                gap: 'var(--gap-medium-plus, 16px)',
                flex: '1 0',
                width: '100%',
              }}
            >
              <Skeleton
                variant="rect"
                animation="wave"
                styles={{
                  height: 0,
                  paddingBottom: 'calc(100% + 82px)',
                }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                styles={{
                  height: 0,
                  paddingBottom: 'calc(100% + 82px)',
                }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                styles={{
                  height: 0,
                  paddingBottom: 'calc(100% + 82px)',
                }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                styles={{
                  height: 0,
                  paddingBottom: 'calc(100% + 82px)',
                }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                styles={{
                  height: 0,
                  paddingBottom: 'calc(100% + 82px)',
                }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                styles={{
                  height: 0,
                  paddingBottom: 'calc(100% + 82px)',
                }}
              />
            </div>
          </div>
        }
      >
        <LibraryTabContent activeTab={activeTab} identityId={identityId} />
      </Suspense>
    </div>
  );
}
