/**
 * @generated SignedSource<<b3ca62c155ec0eb93282eec0e313f79a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProfileActionsGetDmQuery$variables = {
  u1: number;
  u2: number;
};
export type ProfileActionsGetDmQuery$data = {
  readonly get_personal_chat_group: string | null;
};
export type ProfileActionsGetDmQuery = {
  response: ProfileActionsGetDmQuery$data;
  variables: ProfileActionsGetDmQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "u1"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "u2"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "user_1",
        "variableName": "u1"
      },
      {
        "kind": "Variable",
        "name": "user_2",
        "variableName": "u2"
      }
    ],
    "kind": "ScalarField",
    "name": "get_personal_chat_group",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileActionsGetDmQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileActionsGetDmQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc9c2fcc61e235e2f68c562b650af9f3",
    "id": null,
    "metadata": {},
    "name": "ProfileActionsGetDmQuery",
    "operationKind": "query",
    "text": "query ProfileActionsGetDmQuery(\n  $u1: Int!\n  $u2: Int!\n) {\n  get_personal_chat_group(user_1: $u1, user_2: $u2)\n}\n"
  }
};
})();

(node as any).hash = "2c13b0a5e42439b3067a7a65de935e88";

export default node;
