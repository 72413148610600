/**
 * @generated SignedSource<<6183bc1609920de97882454526c33e1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LibraryTabContentPostCardWrapperFragment$data = {
  readonly id: string;
  readonly nodeId: string;
  readonly " $fragmentSpreads": FragmentRefs<"PostCardTokenFragment">;
  readonly " $fragmentType": "LibraryTabContentPostCardWrapperFragment";
};
export type LibraryTabContentPostCardWrapperFragment$key = {
  readonly " $data"?: LibraryTabContentPostCardWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LibraryTabContentPostCardWrapperFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LibraryTabContentPostCardWrapperFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeId",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "includeDefault",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "PostCardTokenFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};

(node as any).hash = "2a0ec9ee8f1ea49fa85c1916c82c56d7";

export default node;
