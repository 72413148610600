/**
 * @generated SignedSource<<48a1084dd5a2c46fc8135f5e2daf9827>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserTimelineFeedAccountIdQuery$variables = {
  id: string;
};
export type UserTimelineFeedAccountIdQuery$data = {
  readonly identitiesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountsCollection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly nodeId: string;
            };
          }>;
        } | null;
        readonly id: string;
        readonly nodeId: string;
      };
    }>;
  } | null;
};
export type UserTimelineFeedAccountIdQuery = {
  response: UserTimelineFeedAccountIdQuery$data;
  variables: UserTimelineFeedAccountIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "identitiesConnection",
    "kind": "LinkedField",
    "name": "identitiesCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "identitiesEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "identities",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accountsConnection",
                "kind": "LinkedField",
                "name": "accountsCollection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "accountsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "accounts",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserTimelineFeedAccountIdQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserTimelineFeedAccountIdQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b98d97ca414445d538bdd1cdd952790f",
    "id": null,
    "metadata": {},
    "name": "UserTimelineFeedAccountIdQuery",
    "operationKind": "query",
    "text": "query UserTimelineFeedAccountIdQuery(\n  $id: BigInt!\n) {\n  identitiesCollection(filter: {id: {eq: $id}}) {\n    edges {\n      node {\n        id\n        nodeId\n        accountsCollection {\n          edges {\n            node {\n              id\n              nodeId\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e0fb7e3c85d7d794f234bd4229ed55e";

export default node;
