import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation updateProfileMutation(
    $input: profilesUpdateInput!
    $filter: profilesFilter!
  ) {
    updateprofilesCollection(set: $input, filter: $filter) {
      records {
        nodeId
        id
        avatar_uri
        banner_uri
        bio
        username
        name
        website
        identities {
          nodeId
          accountsCollection {
            edges {
              node {
                id
                nodeId
              }
            }
          }
        }
      }
    }
  }
`;
