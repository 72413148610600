import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/CMDk/Connections/ConnectionsDialog.module.scss';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {ConnectionsDialogFragment$key} from './__generated__/ConnectionsDialogFragment.graphql';

const Connections = dynamic(
  () => import('@/view/components/CMDk/Connections/Connections'),
  {
    ssr: false,
  }
);

const ConnectionsDialogFragment = graphql`
  fragment ConnectionsDialogFragment on Query {
    ...ConnectionsFragment
  }
`;

export const ConnectionsDialog = ({
  defaultTab,
  trigger,
  id,
  connectionsCountKey,
}: {
  defaultTab: number;
  trigger: React.ReactNode;
  id: string;
  connectionsCountKey: ConnectionsDialogFragment$key | null | undefined;
}) => {
  const connectionsCount = useFragment(
    ConnectionsDialogFragment,
    connectionsCountKey
  );
  const {isMobile} = useBreakpoint();
  return (
    <div>
      <BottomSheet
        dialogOnDesktop
        trigger={trigger}
        className={styles.bottomSheet}
        handleOnClose={useCallback(() => {
          if (isMobile) {
            Router.back();
          }
        }, [isMobile])}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense>
              <Connections
                className={styles.connectionsModal}
                defaultTab={defaultTab}
                onClose={(isPopState: boolean) => {
                  if (!isPopState) {
                    Router.back();
                  }
                  closeDialog();
                }}
                id={id}
                connectionsCountKey={connectionsCount}
              />
            </Suspense>
          ),
          [defaultTab, id, connectionsCount]
        )}
      </BottomSheet>
    </div>
  );
};
