/**
 * @generated SignedSource<<442d3b3267600e67dc8fa4bbbf57245e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_account_type = "beacon" | "magic" | "teztok" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileHeaderActionsUsernameFragment$data = {
  readonly identities: {
    readonly accountsCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly type: enum_account_type;
        };
      }>;
    } | null;
  };
  readonly name: string | null;
  readonly username: string | null;
  readonly " $fragmentType": "ProfileHeaderActionsUsernameFragment";
};
export type ProfileHeaderActionsUsernameFragment$key = {
  readonly " $data"?: ProfileHeaderActionsUsernameFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileHeaderActionsUsernameFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileHeaderActionsUsernameFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "identities",
      "kind": "LinkedField",
      "name": "identities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "accountsConnection",
          "kind": "LinkedField",
          "name": "accountsCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "accountsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "accounts",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "profiles",
  "abstractKey": null
};

(node as any).hash = "b1392000b5fe3dcf80e80690f23846ef";

export default node;
