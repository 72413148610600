import React from 'react';
import Button from '@/view/components/Button';
import RemixIcon from '@/view/components/RemixIcon';

export default function ProfileHeaderActionsFeaturedLinks({
  website,
}: {
  website: string | undefined | null;
}) {
  return (
    <>
      {[
        ...(website
          ? [
              {
                name: 'Website',
                icon: <RemixIcon icon="link-m" size={18} />,
                url: website,
                linkText: website,
              },
            ]
          : []),
      ].map((link, index) =>
        link.url !== '' ? (
          <a
            href={
              link.url?.startsWith('http') ? link.url : `https://${link.url}`
            }
            key={link.name + index}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Button
              text
              onClick={() => {}}
              tooltip={link.name}
              tooltipSide="top"
            >
              {link.icon}
              <span>{link.linkText}</span>
            </Button>
          </a>
        ) : (
          <Button
            key={link.name + index}
            text
            onClick={() => {}}
            tooltip={link.name}
            tooltipSide="top"
          >
            {link.icon}
            <span>{link.linkText}</span>
          </Button>
        )
      )}
    </>
  );
}
