import React from 'react';
import styles from '@/view/styles/components/PlaylistCard/PlaylistCardDefault.module.scss';
import Image from '@/view/components/Image';
import clsx from 'clsx';
import Skeleton from '../Skeleton';
import Link from 'next/link';
import {getImageUrl} from '@/utils/conversions/conversions';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {randomBanner} from '@/utils/images';
import {PlaylistCardDefaultFragment$key} from './__generated__/PlaylistCardDefaultFragment.graphql';

const PlaylistCardDefaultFragment = graphql`
  fragment PlaylistCardDefaultFragment on playlists {
    id
    nodeId
    title
    image_uri
    banner_uri
    playlists_tokensCollection {
      totalCount
    }
  }
`;

export default function PlaylistCardDefault({
  listKey,
  view = 'default',
}: {
  listKey: PlaylistCardDefaultFragment$key;
  view?: 'simple' | 'mini' | 'default' | 'featured' | 'mix';
}) {
  const list = useFragment(PlaylistCardDefaultFragment, listKey);

  const [loadingImage, setLoadingImage] = React.useState(true);

  if (view === 'mini') {
    return (
      <Link href={`/collection/${list.id}`}>
        <div className={styles.miniCard}>
          {list.image_uri ? (
            <div className={styles.imageSingle}>
              <Image dynamic src={getImageUrl(list.image_uri)} alt="" />
            </div>
          ) : (
            <div className={styles.defaultBackground}>
              <Image dynamic src={'/DNSIconOutline.svg'} alt="" />
              <div>
                <span>{list.title}</span>
              </div>
            </div>
          )}
          <div>
            <span>{list.title}</span>
            <span>{list.playlists_tokensCollection?.totalCount} items</span>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div className={styles.defaultWrapper} data-view={view}>
      <Link href={`/collection/${list.id}`} className={styles.root}>
        <div className={styles.details}>
          <span>RECOMMENDED PLAYLIST</span>
          <span>{list.title}</span>
          <span>DNS Music: {list.title}</span>
        </div>
        <div
          className={clsx(
            styles.card,
            view === 'simple' && styles.simpleCard,
            view !== 'default' && styles.noBorderRadius
          )}
        >
          {loadingImage && (
            <Skeleton
              variant="rect"
              animation="wave"
              className={styles.backgroundSkeleton}
            />
          )}
          <PlaylistPostImage
            loadingImage={loadingImage}
            setLoadingImage={setLoadingImage}
            image_uri={
              list.image_uri ||
              list.banner_uri ||
              randomBanner(list.nodeId) ||
              ''
            }
          />
        </div>
      </Link>
    </div>
  );
}

const PlaylistPostImage = ({
  image_uri,
  loadingImage,
  setLoadingImage,
}: {
  image_uri: string | null;
  loadingImage: boolean;
  setLoadingImage: (loading: boolean) => void;
}) => {
  return (
    <div className={styles.image}>
      <Image
        dynamic
        src={getImageUrl(image_uri || '')}
        alt=""
        onLoad={() => {
          setLoadingImage(false);
        }}
        className={clsx(loadingImage && styles.loadingBackground)}
        onError={e => {
          setLoadingImage(false);
        }}
      />
    </div>
  );
};
