import FeedCard from '@/view/components/FeedCard/FeedCard';
import React, {Suspense} from 'react';
import styles from '@/view/styles/pages/profile/blocs/ProfileTab/blocs/UserTimelineFeed.module.scss';
import {
  commitLocalStoreUpdate,
  graphql,
  useLazyLoadQuery,
  usePagination,
} from '@/kits/relay-kit/src';
import Skeleton from '@/view/components/Skeleton';
import {useAccount} from '@/kits/account-kit/src';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import {useCreateStatus} from '@/state/hooks/createStatus';
import {useOnchainActionStatus} from '@/state/hooks/onChainActionStatus';
import ProfileFtueSteps from '@/view/components/FTUESteps/ProfileFtueSteps';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import type {UserTimelineFeedAccountIdQuery as UserTimelineFeedAccountIdQueryType} from './__generated__/UserTimelineFeedAccountIdQuery.graphql';
import {UserTimelineFeedEventsPaginatedQuery} from './__generated__/UserTimelineFeedEventsPaginatedQuery.graphql';
import {UserTimelineFeedEventsPaginated$key} from './__generated__/UserTimelineFeedEventsPaginated.graphql';
import type {UserTimelineFeedEventsQuery as UserTimelineFeedEventsQueryType} from './__generated__/UserTimelineFeedEventsQuery.graphql';

const UserTimelineFeedAccountIdQuery = graphql`
  query UserTimelineFeedAccountIdQuery($id: BigInt!) {
    identitiesCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          nodeId
          accountsCollection {
            edges {
              node {
                id
                nodeId
              }
            }
          }
        }
      }
    }
  }
`;

const UserTimelineFeedEventsQuery = graphql`
  query UserTimelineFeedEventsQuery($id: BigInt!) {
    ...UserTimelineFeedEventsPaginated @arguments(id: $id)
  }
`;

export default function UserTimelineFeed({id}: {id: string}) {
  const {getAccount} = useAccount();
  const user = getAccount();

  const {data: accountQueryData} =
    useLazyLoadQuery<UserTimelineFeedAccountIdQueryType>(
      UserTimelineFeedAccountIdQuery,
      {
        id: id || '',
      },
      {
        skip: !id || id === '',
      }
    );

  const accountId =
    accountQueryData?.identitiesCollection?.edges?.[0]?.node?.accountsCollection
      ?.edges?.[0]?.node?.id;

  const {status: createStatus} = useCreateStatus();
  const {actions} = useOnchainActionStatus();

  const {data: events, retry} =
    useLazyLoadQuery<UserTimelineFeedEventsQueryType>(
      UserTimelineFeedEventsQuery,
      {
        id: accountId || '',
      },
      {
        skip: !accountId || accountId === '',
      }
    );

  React.useEffect(() => {
    if (createStatus === 'done' || actions.length > 0) {
      const timeout = setTimeout(() => {
        retry();
        commitLocalStoreUpdate(updater => {
          updater
            .get(
              `client:root:__HomeTabFeedEventsPaginated_eventsCollection_connection(filter:{"type":{"neq":"follow"}},orderBy:{"created_at":"DescNullsLast"})`
            )
            ?.invalidateRecord();
          updater
            .get(
              `client:root:__HomeTabFilteredFeedEventsPaginated_get_filtered_timeline_events_connection(orderBy:{"created_at":"DescNullsLast"},type:"image")`
            )
            ?.invalidateRecord();
          updater
            .get(
              `client:root:__HomeTabFilteredFeedEventsPaginated_get_filtered_timeline_events_connection(orderBy:{"created_at":"DescNullsLast"},type:"post")`
            )
            ?.invalidateRecord();
          updater
            .get(
              `client:root:__HomeTabFilteredFeedEventsPaginated_get_filtered_timeline_events_connection(orderBy:{"created_at":"DescNullsLast"},type:"audio")`
            )
            ?.invalidateRecord();
          updater
            .get(
              `client:root:__HomeTabFilteredFeedEventsPaginated_get_filtered_timeline_events_connection(orderBy:{"created_at":"DescNullsLast"},type:"video")`
            )
            ?.invalidateRecord();
        });
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [createStatus, actions, retry]);
  return (
    <div className={styles.right}>
      {user.isLoggedIn && user.identityId === id && (
        <Suspense
          fallback={
            <Skeleton
              variant="rect"
              animation="wave"
              borderRadius={10}
              height={420}
              styles={{
                maxWidth: '600px',
              }}
            />
          }
        >
          <ProfileFtueSteps />
        </Suspense>
      )}
      <Posts data={events} />
    </div>
  );
}

const UserTimelineFeedEventsPaginated = graphql`
  fragment UserTimelineFeedEventsPaginated on Query
  @argumentDefinitions(
    id: {type: "BigInt!"}
    first: {type: "Int", defaultValue: 10}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "UserTimelineFeedEventsPaginatedQuery") {
    eventsCollection(
      filter: {account_id: {eq: $id}, type: {neq: follow}}
      orderBy: {created_at: DescNullsLast}
      first: $first
      after: $after
    ) @connection(key: "UserTimelineFeedEventsPaginated_eventsCollection") {
      edges {
        node {
          id
          nodeId
          ...FeedCardEventFragment @arguments(includeToken: true)
        }
      }
    }
  }
`;

const Posts = ({
  data,
}: {
  data: UserTimelineFeedEventsPaginated$key | null | undefined;
}) => {
  const {
    data: paginatedPosts,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
  } = usePagination<
    UserTimelineFeedEventsPaginatedQuery,
    UserTimelineFeedEventsPaginated$key
  >(UserTimelineFeedEventsPaginated, data);

  if (paginatedPosts?.eventsCollection?.edges?.length === 0) {
    return <EmptyProfileCard title="No posts found" small />;
  }

  return (
    <>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                loadNext(10);
              }
            : undefined
        }
        styleOverrides={{
          position: 'absolute',
          minHeight: '50vh',
          bottom: '100vh',
          left: '0px',
          right: '0px',
          opacity: 0,
          zIndex: '-1',
        }}
      >
        {paginatedPosts?.eventsCollection?.edges?.map((edge, i) => {
          return (
            <Suspense
              key={edge.node.id + i}
              fallback={
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={'min(100vw, 600px)'}
                  height={780}
                  styles={{
                    maxWidth: '100vw',
                  }}
                />
              }
            >
              <FeedCard eventKey={edge.node} key={edge.node.id + i} />
            </Suspense>
          );
        })}
      </InfiniteScrollContainer>
      {(isLoading || isLoadingNext) && (
        <Skeleton
          variant="rect"
          animation="wave"
          width={'min(100vw, 600px)'}
          height={780}
          styles={{
            maxWidth: '100vw',
          }}
        />
      )}
    </>
  );
};
