/**
 * @generated SignedSource<<b797f51db56f5236a87400d8cd7e32f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type profilesUpdateInput = {
  avatar_uri?: string | null;
  banner_uri?: string | null;
  bio?: string | null;
  created_at?: string | null;
  email?: string | null;
  identity_id?: string | null;
  name?: string | null;
  updated_at?: string | null;
  username?: string | null;
  website?: string | null;
};
export type profilesFilter = {
  and?: ReadonlyArray<profilesFilter> | null;
  avatar_uri?: StringFilter | null;
  banner_uri?: StringFilter | null;
  bio?: StringFilter | null;
  id?: BigIntFilter | null;
  identity_id?: BigIntFilter | null;
  name?: StringFilter | null;
  nodeId?: IDFilter | null;
  not?: profilesFilter | null;
  or?: ReadonlyArray<profilesFilter> | null;
  username?: StringFilter | null;
  website?: StringFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type StringFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  ilike?: string | null;
  in?: ReadonlyArray<string> | null;
  iregex?: string | null;
  is?: FilterIs | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  regex?: string | null;
  startsWith?: string | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type updateProfileMutation$variables = {
  filter: profilesFilter;
  input: profilesUpdateInput;
};
export type updateProfileMutation$data = {
  readonly updateprofilesCollection: {
    readonly records: ReadonlyArray<{
      readonly avatar_uri: string | null;
      readonly banner_uri: string | null;
      readonly bio: string | null;
      readonly id: string;
      readonly identities: {
        readonly accountsCollection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly nodeId: string;
            };
          }>;
        } | null;
        readonly nodeId: string;
      };
      readonly name: string | null;
      readonly nodeId: string;
      readonly username: string | null;
      readonly website: string | null;
    }>;
  };
};
export type updateProfileMutation = {
  response: updateProfileMutation$data;
  variables: updateProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "set",
        "variableName": "input"
      }
    ],
    "concreteType": "profilesUpdateResponse",
    "kind": "LinkedField",
    "name": "updateprofilesCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "profiles",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar_uri",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "banner_uri",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "website",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "identities",
            "kind": "LinkedField",
            "name": "identities",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accountsConnection",
                "kind": "LinkedField",
                "name": "accountsCollection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "accountsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "accounts",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProfileMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateProfileMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "35e220a41b4eec8cf8fd3ba9110ebc09",
    "id": null,
    "metadata": {},
    "name": "updateProfileMutation",
    "operationKind": "mutation",
    "text": "mutation updateProfileMutation(\n  $input: profilesUpdateInput!\n  $filter: profilesFilter!\n) {\n  updateprofilesCollection(set: $input, filter: $filter) {\n    records {\n      nodeId\n      id\n      avatar_uri\n      banner_uri\n      bio\n      username\n      name\n      website\n      identities {\n        nodeId\n        accountsCollection {\n          edges {\n            node {\n              id\n              nodeId\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c291118a5b0c017262d7e3fdea30f1eb";

export default node;
