import React from 'react';
import styles from '@/view/styles/components/ProfileHeader/blocs/ProfileActions/blocs/ProfileHeaderActionsUsername.module.scss';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import profileFragment from '@/graphql/profile-fragment';
import {useRouter} from 'next/router';
import {trim} from '@/utils/trim';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import Tooltip from '@/view/components/Tooltip';
import {ProfileHeaderActionsUsernameFragment$key} from './__generated__/ProfileHeaderActionsUsernameFragment.graphql';

const ProfileHeaderActionsUsernameFragment = graphql`
  fragment ProfileHeaderActionsUsernameFragment on profiles {
    name
    username
    identities {
      accountsCollection {
        edges {
          node {
            type
          }
        }
      }
    }
  }
`;

export default function ProfileHeaderActionsUsername({
  userProfileKey,
}: {
  userProfileKey: ProfileHeaderActionsUsernameFragment$key | undefined | null;
}) {
  const userProfile = useFragment(
    ProfileHeaderActionsUsernameFragment,
    userProfileKey
  );
  const router = useRouter();
  const {id: rawId} = router.query;
  const id = typeof rawId === 'string' ? rawId : rawId?.[0];
  const {isMobile} = useBreakpoint();

  const isNonDNSUser =
    userProfile?.identities?.accountsCollection?.edges?.[0]?.node?.type ===
    'teztok';
  return (
    <div className={styles.root}>
      <div className={styles.name}>
        {userProfile?.name
          ? userProfile?.name
          : !!userProfile?.username
          ? userProfile?.username
          : trim(id || 'User')}
        {isNonDNSUser && (
          <Tooltip text="This user is not on DNS" className="non-dns-user-tag">
            <span />
          </Tooltip>
        )}
      </div>
      <div className={styles.username}>
        {!!userProfile?.username
          ? `@${userProfile?.username}`
          : isMobile
          ? trim(id || '')
          : id}
      </div>
    </div>
  );
}
