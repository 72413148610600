import React, {useCallback} from 'react';
import styles from '@/view/styles/components/ProfileHeader/blocs/ProfileBanner/ProfileBanner.module.scss';
import Image from '@/view/components/Image';
import {Avatar} from '@/view/components/Avatar';
import clsx from 'clsx';
import Dialog from '@/view/components/Dialog';
import Router from 'next/router';
import ProfilePictureView from '@/view/components/ProfilePictureView';
import {MemoIcon} from '@/view/components/utils/MemoChildren';
import Skeleton from '@/view/components/Skeleton';
import dynamic from 'next/dynamic';
import RemixIcon from '@/view/components/RemixIcon';
import {MenuOptionType} from '@/view/types/types';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {profileFragment$key} from '@/graphql/__generated__/profileFragment.graphql';
import profileFragment from '@/graphql/profile-fragment';
import {graphql, useFragment, useMutation} from '@/kits/relay-kit/src';
import {getImageUrl} from '@/utils/conversions/conversions';
import updateProfileMutation from '@/graphql/update-profile';
import type {updateProfileMutation as ProfileMutationType} from '@/graphql/__generated__/updateProfileMutation.graphql';
import {imagesToCIDs} from '@/utils/api/imageToCID';
import {ProfileBannerFragment$key} from './__generated__/ProfileBannerFragment.graphql';
const Uploader = dynamic(
  () => import('@/view/components/ProfilePageComponents/Uploader'),
  {
    ssr: false,
  }
);
const LibraryInput = dynamic(
  () =>
    import(
      '@/view/components/ProfileHeader/blocs/ProfileBanner/blocs/LibraryInput'
    ),
  {
    ssr: false,
  }
);
const CameraInput = dynamic(
  () =>
    import(
      '@/view/components/ProfileHeader/blocs/ProfileBanner/blocs/CameraInput'
    ),
  {
    ssr: false,
  }
);

const ProfileBannerFragment = graphql`
  fragment ProfileBannerFragment on profiles {
    nodeId
    id
    avatar_uri
    banner_uri
    tezos_profilesCollection {
      edges {
        node {
          logo
        }
      }
    }
  }
`;

export default function ProfileBanner({
  id,
  isOwnProfile,
  userProfileKey,
  loadingBanner,
  setLoadingBanner,
  bannerPlaceholder,
}: {
  id: string;
  isOwnProfile: boolean;
  userProfileKey: ProfileBannerFragment$key | undefined;
  loadingBanner: boolean;
  setLoadingBanner: React.Dispatch<React.SetStateAction<boolean>>;
  bannerPlaceholder: string | undefined;
}) {
  const userProfile = useFragment(ProfileBannerFragment, userProfileKey);
  const [field, setField] = React.useState<'avatar' | 'banner'>('avatar');
  const [settingAvatar, setSettingAvatar] = React.useState(false);
  const [settingBanner, setSettingBanner] = React.useState(false);
  const {isMobile} = useBreakpoint();
  const libraryInput = React.useRef<HTMLInputElement>(null);
  const cameraInput = React.useRef<HTMLInputElement>(null);

  const supportsCapture = React.useMemo(() => {
    if (typeof window === 'undefined') {
      return false;
    }
    if (typeof window.document === 'undefined') {
      return false;
    }
    const input = document.createElement('input');
    if (input.capture !== undefined) {
      return true;
    }
    return false;
  }, []);

  const [updateProfile] = useMutation<ProfileMutationType>(
    updateProfileMutation
  );

  const editAvatarOptions: MenuOptionType[] = [
    ...(userProfile?.avatar_uri !== ''
      ? [
          {
            icon: <RemixIcon icon="delete-bin-7-line" size={20} />,
            label: 'Remove',
            value: 'remove',
            onClick: async () => {
              setSettingAvatar(true);
              updateProfile({
                variables: {
                  input: {
                    avatar_uri: '',
                    updated_at: 'now',
                  },
                  filter: {
                    identity_id: {eq: id},
                  },
                },
                updater: store => {
                  if (!userProfile) return;
                  const affectedProfile = store.get(userProfile.nodeId);
                  affectedProfile?.setValue('', 'avatar_uri');
                },
              }).then(() => {
                setSettingAvatar(false);
              });
            },
          },
        ]
      : []),
    {
      icon: <RemixIcon icon="image-line" size={20} />,
      label: isMobile ? 'Library' : 'Add photo',
      value: 'library',
      onClick: () => {
        libraryInput.current?.click();
      },
      tooltip: `Upload a photo from your computer, max 25 MB`,
    },
    ...(supportsCapture
      ? [
          {
            icon: <RemixIcon icon="camera-line" size={20} />,
            label: 'Camera',
            value: 'camera',
            onClick: () => {
              cameraInput.current?.click();
            },
          },
        ]
      : []),
  ];

  const editBannerOptions: MenuOptionType[] = [
    ...(userProfile?.banner_uri !== ''
      ? [
          {
            icon: <RemixIcon icon="delete-bin-7-line" size={20} />,
            label: 'Remove',
            value: 'remove',
            onClick: async () => {
              setSettingBanner(true);
              updateProfile({
                variables: {
                  input: {
                    banner_uri: '',
                  },
                  filter: {
                    identity_id: {eq: id},
                  },
                },
                updater: store => {
                  if (!userProfile) return;
                  const affectedProfile = store.get(userProfile.nodeId);
                  affectedProfile?.setValue('', 'banner_uri');
                },
              }).then(() => {
                setSettingBanner(false);
              });
            },
          },
        ]
      : []),
    {
      icon: <RemixIcon icon="image-line" size={20} />,
      label: isMobile ? 'Library' : 'Add photo',
      value: 'library',
      onClick: () => {
        libraryInput.current?.click();
      },
      tooltip: `Upload a photo from your computer, max 25 MB`,
    },
    ...(supportsCapture
      ? [
          {
            icon: <RemixIcon icon="camera-line" size={20} />,
            label: 'Camera',
            value: 'camera',
            onClick: () => {
              cameraInput.current?.click();
            },
          },
        ]
      : []),
  ];

  return (
    <div className={styles.header}>
      <div
        className={clsx(styles.banner, loadingBanner && styles.bannerIsLoading)}
      >
        <Image
          dynamic
          src={
            getImageUrl(userProfile?.banner_uri || '') ||
            bannerPlaceholder ||
            ''
          }
          onLoad={e => {
            setLoadingBanner(false);
            e.currentTarget.style.background = 'none';
          }}
          onError={e => {
            // e.target.style.display = 'none';
          }}
          alt=""
          key={`banner-${userProfile?.id}`}
        />
        <div className={styles.bannerOverlay} />
        {isOwnProfile && settingBanner && (
          <div className={styles.loading}>
            <RemixIcon icon="loader-4-line" size={84} />
          </div>
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.avatar} data-loading={false}>
          <Skeleton variant="circle" animation="wave" />
          <Dialog
            trigger={
              <div
                onClick={() => {
                  if (isMobile) {
                    Router.push(
                      {pathname: Router.pathname, query: {...Router.query}},
                      Router.asPath + '#',
                      {
                        shallow: true,
                        scroll: false,
                      }
                    );
                  }
                }}
              >
                {!!(
                  userProfile?.avatar_uri ||
                  userProfile?.tezos_profilesCollection?.edges?.[0]?.node?.logo
                ) ? (
                  <Avatar
                    src={getImageUrl(
                      userProfile?.avatar_uri ||
                        userProfile?.tezos_profilesCollection?.edges?.[0]?.node
                          ?.logo ||
                        ''
                    )}
                    alt=""
                    key={`avatar-${userProfile?.id}`}
                  >
                    <MemoIcon
                      Component={'user-line'}
                      size={isMobile ? 42 : 84}
                    />
                  </Avatar>
                ) : (
                  <MemoIcon Component={'user-line'} size={isMobile ? 42 : 84} />
                )}
                {isOwnProfile && (
                  <>
                    {settingAvatar && (
                      <div className={styles.loading}>
                        <RemixIcon icon="loader-4-line" size={84} />
                      </div>
                    )}
                    <Uploader
                      field="avatar"
                      setField={setField}
                      options={editAvatarOptions}
                      isUploadingDisabled={settingBanner || settingAvatar}
                    />
                  </>
                )}
              </div>
            }
            handleOnClose={useCallback(() => {
              if (isMobile) {
                Router.back();
              }
            }, [isMobile])}
          >
            {useCallback(
              (closeDialog: () => void) => (
                <ProfilePictureView
                  avatar={getImageUrl(userProfile?.avatar_uri || '')}
                  closeDialog={(isPopState: boolean) => {
                    closeDialog();
                  }}
                />
              ),
              [userProfile?.avatar_uri]
            )}
          </Dialog>
        </div>
        {isOwnProfile && (
          <Uploader
            field="banner"
            setField={setField}
            options={editBannerOptions}
            isUploadingDisabled={settingBanner || settingAvatar}
          />
        )}
      </div>
      <LibraryInput
        libraryInput={libraryInput}
        field={field}
        updateProfile={profile => {
          if (profile.avatar) {
            setSettingAvatar(true);
            imagesToCIDs([
              {
                id: 'avatar',
                base64encoded: true,
                data: profile.avatar.replace(
                  /^data:image\/(png|jpg|jpeg|gif|webp);base64,/,
                  ''
                ),
              },
            ]).then(res => {
              const avatarCID = res.find(a => a.id === 'avatar')?.cid;
              updateProfile({
                variables: {
                  input: {
                    avatar_uri: avatarCID,
                    updated_at: 'now',
                  },
                  filter: {
                    identity_id: {eq: id},
                  },
                },
                updater: store => {
                  if (!userProfile?.nodeId) return;
                  const affectedMutation = store.getRootField(
                    'updateprofilesCollection'
                  );
                  const newUpdatedRecords =
                    affectedMutation?.getLinkedRecords('records');
                  const newUpdatedRecord = newUpdatedRecords?.[0];
                  const newAvatar = newUpdatedRecord?.getValue('avatar_uri');
                  if (!userProfile) return;
                  const affectedProfile = store.get(userProfile.nodeId);
                  affectedProfile?.setValue(newAvatar, 'avatar_uri');
                },
              })
                .then(res => {
                  setSettingAvatar(false);
                })
                .catch(err => {
                  setSettingAvatar(false);
                });
            });
          }
          if (profile.banner) {
            setSettingBanner(true);
            imagesToCIDs([
              {
                id: 'banner',
                base64encoded: true,
                data: profile.banner.replace(
                  /^data:image\/(png|jpg|jpeg|gif|webp);base64,/,
                  ''
                ),
              },
            ]).then(res => {
              const bannerCID = res.find(a => a.id === 'banner')?.cid;
              updateProfile({
                variables: {
                  input: {
                    banner_uri: bannerCID,
                    updated_at: 'now',
                  },
                  filter: {
                    identity_id: {eq: id},
                  },
                },
                updater: store => {
                  if (!userProfile?.nodeId) return;
                  const affectedMutation = store.getRootField(
                    'updateprofilesCollection'
                  );
                  const newUpdatedRecords =
                    affectedMutation?.getLinkedRecords('records');
                  const newUpdatedRecord = newUpdatedRecords?.[0];
                  const newBanner = newUpdatedRecord?.getValue('banner_uri');
                  if (!userProfile) return;
                  const affectedProfile = store.get(userProfile.nodeId);
                  affectedProfile?.setValue(newBanner, 'banner_uri');
                },
              })
                .then(res => {
                  setSettingBanner(false);
                })
                .catch(err => {
                  setSettingBanner(false);
                });
            });
          }
        }}
      />
      <CameraInput
        cameraInput={cameraInput}
        field={field}
        updateProfile={profile => {
          if (profile.avatar) {
            setSettingAvatar(true);
            imagesToCIDs([
              {
                id: 'avatar',
                base64encoded: true,
                data: profile.avatar.replace(
                  /^data:image\/(png|jpg|jpeg|gif|webp);base64,/,
                  ''
                ),
              },
            ]).then(res => {
              const avatarCID = res.find(a => a.id === 'avatar')?.cid;
              updateProfile({
                variables: {
                  input: {
                    avatar_uri: avatarCID,
                    updated_at: 'now',
                  },
                  filter: {
                    identity_id: {eq: id},
                  },
                },
                updater: store => {
                  if (!userProfile?.nodeId) return;
                  const affectedMutation = store.getRootField(
                    'updateprofilesCollection'
                  );
                  const newUpdatedRecords =
                    affectedMutation?.getLinkedRecords('records');
                  const newUpdatedRecord = newUpdatedRecords?.[0];
                  const newAvatar = newUpdatedRecord?.getValue('avatar_uri');
                  if (!userProfile) return;
                  const affectedProfile = store.get(userProfile.nodeId);
                  affectedProfile?.setValue(newAvatar, 'avatar_uri');
                },
              })
                .then(res => {
                  setSettingAvatar(false);
                })
                .catch(err => {
                  setSettingAvatar(false);
                });
            });
          }
          if (profile.banner) {
            setSettingBanner(true);
            imagesToCIDs([
              {
                id: 'banner',
                base64encoded: true,
                data: profile.banner.replace(
                  /^data:image\/(png|jpg|jpeg|gif|webp);base64,/,
                  ''
                ),
              },
            ]).then(res => {
              const bannerCID = res.find(a => a.id === 'banner')?.cid;
              updateProfile({
                variables: {
                  input: {
                    banner_uri: bannerCID,
                    updated_at: 'now',
                  },
                  filter: {
                    identity_id: {eq: id},
                  },
                },
                updater: store => {
                  if (!userProfile?.nodeId) return;
                  const affectedMutation = store.getRootField(
                    'updateprofilesCollection'
                  );
                  const newUpdatedRecords =
                    affectedMutation?.getLinkedRecords('records');
                  const newUpdatedRecord = newUpdatedRecords?.[0];
                  const newBanner = newUpdatedRecord?.getValue('banner_uri');
                  if (!userProfile) return;
                  const affectedProfile = store.get(userProfile.nodeId);
                  affectedProfile?.setValue(newBanner, 'banner_uri');
                },
              })
                .then(res => {
                  setSettingBanner(false);
                })
                .catch(err => {
                  setSettingBanner(false);
                });
            });
          }
        }}
      />
    </div>
  );
}
