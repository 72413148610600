import React from 'react';
import Tabs from '../Tabs';
import ProfileBanner from './blocs/ProfileBanner/ProfileBanner';
import ProfileActions from './blocs/ProfileActions/ProfileActions';
import {MenuOptionType, TabType} from '@/view/types/types';
import styles from '@/view/styles/components/ProfileHeader/ProfileHeader.module.scss';
import {randomBanner} from '@/utils/images';
import {trim} from '@/utils/trim';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import RemixIcon from '../RemixIcon';
import type {ProfileHeaderQuery as ProfileHeaderQueryType} from './__generated__/ProfileHeaderQuery.graphql';

const tabs: TabType[] = [
  {
    value: 'profile',
    label: 'Profile',
  },
  {
    value: 'library',
    label: 'Library',
  },
];

const ProfileHeaderQuery = graphql`
  query ProfileHeaderQuery($id: BigInt!) {
    identitiesCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          nodeId
          id
          profilesCollection {
            edges {
              node {
                nodeId
                id
                username
                ...ProfileBannerFragment
                ...ProfileActionsFragment
              }
            }
          }
        }
      }
    }
  }
`;

export default function ProfileHeader({
  profileWallet,
  loggedIn,
  tab,
  setTab,
  isOwnProfile,
}: {
  profileWallet: string;
  loggedIn: boolean;
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  isOwnProfile: boolean;
}) {
  const [loadingBanner, setLoadingBanner] = React.useState<boolean>(true);

  const [bannerPlaceholder, setBannerPlaceholder] = React.useState<string>();

  const {data: userProfileData} = useLazyLoadQuery<ProfileHeaderQueryType>(
    ProfileHeaderQuery,
    {
      id: profileWallet || '',
    },
    {
      skip: !profileWallet || profileWallet === '',
    }
  );
  const userAccount = userProfileData?.identitiesCollection?.edges?.[0]?.node;

  React.useEffect(() => {
    setBannerPlaceholder(randomBanner(userAccount?.id || '', 1400));
  }, [userAccount?.id]);

  const options: MenuOptionType[] = [
    {
      icon: <RemixIcon icon="forbid-line" size={24} />,
      label: `Block ${
        userAccount?.profilesCollection?.edges?.[0].node.username
          ? `@${userAccount?.profilesCollection?.edges?.[0].node.username}`
          : trim(userAccount?.id || '')
      }`,
      value: 'block',
      onClick: async () => {},
      disabled: true,
      disabledTooltip: 'Coming soon',
    },
  ];

  return (
    <>
      <ProfileBanner
        id={profileWallet}
        loadingBanner={loadingBanner}
        setLoadingBanner={setLoadingBanner}
        bannerPlaceholder={bannerPlaceholder}
        isOwnProfile={isOwnProfile}
        userProfileKey={
          userProfileData?.identitiesCollection?.edges?.[0]?.node
            ?.profilesCollection?.edges?.[0].node
        }
      />
      <ProfileActions
        isOwnProfile={isOwnProfile}
        userProfileKey={
          userProfileData?.identitiesCollection?.edges?.[0]?.node
            ?.profilesCollection?.edges?.[0]?.node || null
        }
        id={profileWallet}
        loggedIn={loggedIn}
        options={options}
      />
      <div className={styles.tabs}>
        <Tabs
          tabs={tabs}
          tab={tab}
          setTab={(tab: number) => {
            setTab(tab);
          }}
        />
      </div>
    </>
  );
}
