/**
 * @generated SignedSource<<fb7eac12664a076488d8129073cec261>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileBannerFragment$data = {
  readonly avatar_uri: string | null;
  readonly banner_uri: string | null;
  readonly id: string;
  readonly nodeId: string;
  readonly tezos_profilesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly logo: string | null;
      };
    }>;
  } | null;
  readonly " $fragmentType": "ProfileBannerFragment";
};
export type ProfileBannerFragment$key = {
  readonly " $data"?: ProfileBannerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileBannerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileBannerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "banner_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "tezos_profilesConnection",
      "kind": "LinkedField",
      "name": "tezos_profilesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "tezos_profilesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "tezos_profiles",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "logo",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "profiles",
  "abstractKey": null
};

(node as any).hash = "0f9f848ed33e05520e27bf222054a91d";

export default node;
