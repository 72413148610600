import React from 'react';
import styles from '@/view/styles/components/FTUESteps/ProfileFtueSteps.module.scss';
import Accordion from '@/view/components/Accordion/Accordion';
import RemixIcon from '@/view/components/RemixIcon';
import ScrollableContainer from '@/view/components/ScrollableContainer';
import {useAccount} from '@/kits/account-kit/src';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import Button from '@/view/components/Button';
import {ActionsMenuActions} from '@/state/hooks/actionsMenu';
import {getImageUrl} from '@/utils/conversions/conversions';
import Input from '../Input/Input';
import {useMutation} from '@/kits/relay-kit/src';
import updateProfileMutation from '@/graphql/update-profile';
import type {updateProfileMutation as ProfileMutationType} from '@/graphql/__generated__/updateProfileMutation.graphql';
import {ftueAvatars, ftueBanners} from '@/view/placeholders/placeholderData';
import {Avatar} from '../Avatar';
import type {ProfileFtueStepsProfileQuery as ProfileFtueStepsProfileQueryType} from './__generated__/ProfileFtueStepsProfileQuery.graphql';

const ProfileFtueStepsProfileQuery = graphql`
  query ProfileFtueStepsProfileQuery($id: BigInt!) {
    identitiesCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          nodeId
          id
          profilesCollection {
            edges {
              node {
                id
                nodeId
                username
                avatar_uri
                banner_uri
                bio
              }
            }
          }
        }
      }
    }
  }
`;

export default function ProfileFtueSteps() {
  const {getAccount} = useAccount();
  const user = getAccount();

  const identityId = user.isLoggedIn ? user.identityId : undefined;
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const {data: profileData, retry} =
    useLazyLoadQuery<ProfileFtueStepsProfileQueryType>(
      ProfileFtueStepsProfileQuery,
      {
        id: identityId || '',
      },
      {
        skip: !identityId || identityId === '',
      }
    );

  const profile = profileData?.identitiesCollection?.edges?.[0]?.node;

  React.useEffect(() => {
    retry();
  }, []);

  const [profileShared, setProfileShared] = React.useState(false);

  const totalCompleted = [
    !!profile?.profilesCollection?.edges?.[0]?.node?.avatar_uri,
    !!profile?.profilesCollection?.edges?.[0]?.node?.banner_uri,
    !!profile?.profilesCollection?.edges?.[0]?.node?.bio,
    profileShared || localStorage.getItem('profileShared') === 'true',
  ].filter(Boolean).length;

  const [bio, setBio] = React.useState<string | null>(null);

  const [updateProfile, {loading: updatingProfile}] =
    useMutation<ProfileMutationType>(updateProfileMutation);

  const accordionItems: {
    value: string;
    header: React.ReactNode;
    content: React.ReactNode;
    disabled?: boolean;
  }[] = [
    {
      value: 'add-a-profile-picture',
      header: (
        <div
          className={styles.accordionHeader}
          data-complete={
            !!profile?.profilesCollection?.edges?.[0]?.node?.avatar_uri
          }
        >
          <RemixIcon
            icon={
              !!profile?.profilesCollection?.edges?.[0]?.node?.avatar_uri
                ? 'check-line'
                : 'camera-2-fill'
            }
            size={24}
          />
          <span>Add a profile picture</span>
        </div>
      ),
      content: (
        <div className={styles.accordionContent}>
          <span>Choose a profile picture to show your unique style</span>
          <ScrollableContainer
            cols={3}
            fullWidth
            className={styles.ftueScrollable}
            variant="transparent"
          >
            {ftueAvatars?.map((avatar, i) => {
              return (
                <div
                  key={avatar + i}
                  className={styles.avatar}
                  data-selected={
                    profile?.profilesCollection?.edges?.[0]?.node
                      ?.avatar_uri === avatar
                  }
                >
                  <Avatar
                    src={getImageUrl(avatar)}
                    onClick={() => {
                      updateProfile({
                        variables: {
                          input: {
                            avatar_uri: avatar,
                            updated_at: 'now',
                          },
                          filter: {
                            identity_id: {eq: identityId},
                          },
                        },
                        updater: store => {
                          const affectedMutation = store.getRootField(
                            'updateprofilesCollection'
                          );
                          const newUpdatedRecords =
                            affectedMutation?.getLinkedRecords('records');
                          const newUpdatedRecord = newUpdatedRecords?.[0];
                          const newAvatar =
                            newUpdatedRecord?.getValue('avatar_uri');
                          const affectedProfile = store.get(
                            newUpdatedRecord.getValue('nodeId') || ''
                          );
                          affectedProfile?.setValue(newAvatar, 'avatar_uri');
                        },
                      }).then(results => {
                        retry();
                      });
                    }}
                  />
                  {avatar ===
                    profile?.profilesCollection?.edges?.[0]?.node
                      ?.avatar_uri && (
                    <div>
                      <RemixIcon icon="check-line" size={24} />
                    </div>
                  )}
                </div>
              );
            })}
          </ScrollableContainer>
        </div>
      ),
      disabled: !!profile?.profilesCollection?.edges?.[0]?.node?.avatar_uri,
    },
    {
      value: 'add-a-description',
      header: (
        <div
          className={styles.accordionHeader}
          data-complete={!!profile?.profilesCollection?.edges?.[0]?.node?.bio}
        >
          <RemixIcon
            icon={
              !!profile?.profilesCollection?.edges?.[0]?.node?.bio
                ? 'check-line'
                : 'quote-text'
            }
            size={24}
          />
          <span>Add a description</span>
        </div>
      ),
      content: (
        <div className={styles.accordionContent}>
          <span>What the world should know about you</span>
          <div className={styles.leftPadding}>
            <Input
              type="text"
              multiline
              defaultValue={bio || ''}
              onChange={setBio}
              fullWidth
              simple
              rows={4}
              placeholder="Enter a bio"
              disabled={
                updatingProfile ||
                !!profile?.profilesCollection?.edges?.[0]?.node?.bio
              }
            />
            {!profile?.profilesCollection?.edges?.[0]?.node?.bio && (
              <Button
                accent
                onClick={() => {
                  updateProfile({
                    variables: {
                      input: {
                        bio: bio,
                        updated_at: 'now',
                      },
                      filter: {
                        identity_id: {eq: identityId},
                      },
                    },
                    updater: store => {
                      const affectedMutation = store.getRootField(
                        'updateprofilesCollection'
                      );
                      const newUpdatedRecords =
                        affectedMutation?.getLinkedRecords('records');
                      const newUpdatedRecord = newUpdatedRecords?.[0];
                      const newBio = newUpdatedRecord?.getValue('bio');
                      const affectedProfile = store.get(
                        newUpdatedRecord.getValue('nodeId') || ''
                      );
                      affectedProfile?.setValue(newBio, 'bio');
                    },
                  }).then(results => {});
                }}
                className={styles.saveButton}
                disabled={updatingProfile}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      ),
      disabled: !!profile?.profilesCollection?.edges?.[0]?.node?.bio,
    },
    {
      value: 'add-a-banner',
      header: (
        <div
          className={styles.accordionHeader}
          data-complete={
            !!profile?.profilesCollection?.edges?.[0]?.node?.banner_uri
          }
        >
          <RemixIcon
            icon={
              !!profile?.profilesCollection?.edges?.[0]?.node?.banner_uri
                ? 'check-line'
                : 'image-fill'
            }
            size={24}
          />
          <span>Add a background picture</span>
        </div>
      ),
      content: (
        <div className={styles.accordionContent}>
          <span>Choose a background for a personalized experience</span>
          <ScrollableContainer
            cols={3}
            fullWidth
            className={styles.ftueScrollable}
            variant="transparent"
          >
            {ftueBanners?.map((banner, i) => {
              return (
                <div
                  key={banner + i}
                  className={styles.banner}
                  data-selected={
                    profile?.profilesCollection?.edges?.[0]?.node
                      ?.banner_uri === banner
                  }
                >
                  <Avatar
                    src={getImageUrl(banner)}
                    onClick={() => {
                      updateProfile({
                        variables: {
                          input: {
                            banner_uri: banner,
                            updated_at: 'now',
                          },
                          filter: {
                            identity_id: {eq: identityId},
                          },
                        },
                        updater: store => {
                          const affectedMutation = store.getRootField(
                            'updateprofilesCollection'
                          );
                          const newUpdatedRecords =
                            affectedMutation?.getLinkedRecords('records');
                          const newUpdatedRecord = newUpdatedRecords?.[0];
                          const newBanner =
                            newUpdatedRecord?.getValue('banner_uri');
                          const affectedProfile = store.get(
                            newUpdatedRecord.getValue('nodeId') || ''
                          );
                          affectedProfile?.setValue(newBanner, 'banner_uri');
                        },
                      }).then(results => {
                        retry();
                      });
                    }}
                  />
                  {banner ===
                    profile?.profilesCollection?.edges?.[0]?.node
                      ?.banner_uri && (
                    <div>
                      <RemixIcon icon="check-line" size={24} />
                    </div>
                  )}
                </div>
              );
            })}
          </ScrollableContainer>
        </div>
      ),
      disabled: !!profile?.profilesCollection?.edges?.[0]?.node?.banner_uri,
    },
    {
      value: 'share-your-profile',
      header: (
        <div
          className={styles.accordionHeader}
          data-complete={
            profileShared || localStorage.getItem('profileShared') === 'true'
          }
        >
          <RemixIcon
            icon={
              profileShared || localStorage.getItem('profileShared') === 'true'
                ? 'check-line'
                : 'share-forward-fill'
            }
            size={24}
          />
          <span>Share your profile</span>
        </div>
      ),
      content: (
        <div className={styles.accordionContent}>
          <span>Share your page to the world and start your journey</span>
          <div className={styles.leftPadding}>
            <Button
              accent
              onClick={() => {
                ActionsMenuActions.openActionsMenu({
                  type: 'user',
                  user: {
                    id: identityId || '',
                    nodeId: profile?.nodeId || '',
                    address: undefined,
                    username:
                      profile?.profilesCollection?.edges?.[0]?.node?.username ||
                      undefined,
                  },
                  page: 1,
                  title:
                    profile?.profilesCollection?.edges?.[0]?.node?.username ||
                    '',
                  description:
                    profile?.profilesCollection?.edges?.[0]?.node?.bio ||
                    undefined,
                  image: getImageUrl(
                    profile?.profilesCollection?.edges?.[0]?.node?.avatar_uri ||
                      ''
                  ),
                });
                localStorage.setItem('profileShared', 'true');
                setProfileShared(true);
                retry();
              }}
              className={styles.createPostButton}
            >
              <RemixIcon icon="share-forward-line" size={18} />
              <span>Share profile</span>
            </Button>
          </div>
        </div>
      ),
      disabled:
        profileShared || localStorage.getItem('profileShared') === 'true',
    },
  ];
  return (
    totalCompleted < accordionItems.length && (
      <div className={styles.root}>
        <div className={styles.info}>
          <div>Getting Started</div>
          <span>
            {`Here are a few steps to get your profile ready.\nYou'll be up and running in no time.`}
          </span>
          <div>{totalCompleted}/4 complete</div>
        </div>
        <Accordion
          openMultiple
          openByDefault
          fullWidthTrigger
          items={accordionItems}
          className={styles.accordion}
        />
      </div>
    )
  );
}
