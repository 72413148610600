/**
 * @generated SignedSource<<b2696405e73eaafe66188334d5d7ff4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileHeaderActionsUserDetailsConnectionsCountQuery$variables = {
  id: string;
};
export type ProfileHeaderActionsUserDetailsConnectionsCountQuery$data = {
  readonly followers: {
    readonly totalCount: number;
  } | null;
  readonly following: {
    readonly totalCount: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectionsDialogFragment">;
};
export type ProfileHeaderActionsUserDetailsConnectionsCountQuery = {
  response: ProfileHeaderActionsUserDetailsConnectionsCountQuery$data;
  variables: ProfileHeaderActionsUserDetailsConnectionsCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "eq",
    "variableName": "id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = {
  "alias": "followers",
  "args": [
    {
      "fields": [
        {
          "fields": (v1/*: any*/),
          "kind": "ObjectValue",
          "name": "followee_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "filter"
    }
  ],
  "concreteType": "followsConnection",
  "kind": "LinkedField",
  "name": "followsCollection",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": "following",
  "args": [
    {
      "fields": [
        {
          "fields": (v1/*: any*/),
          "kind": "ObjectValue",
          "name": "follower_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "filter"
    }
  ],
  "concreteType": "followsConnection",
  "kind": "LinkedField",
  "name": "followsCollection",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileHeaderActionsUserDetailsConnectionsCountQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ConnectionsDialogFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileHeaderActionsUserDetailsConnectionsCountQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "6c9a85f46f1dede919d2d33103281150",
    "id": null,
    "metadata": {},
    "name": "ProfileHeaderActionsUserDetailsConnectionsCountQuery",
    "operationKind": "query",
    "text": "query ProfileHeaderActionsUserDetailsConnectionsCountQuery(\n  $id: BigInt!\n) {\n  followers: followsCollection(filter: {followee_id: {eq: $id}}) {\n    totalCount\n  }\n  following: followsCollection(filter: {follower_id: {eq: $id}}) {\n    totalCount\n  }\n  ...ConnectionsDialogFragment\n}\n\nfragment ConnectionsDialogFragment on Query {\n  ...ConnectionsFragment\n}\n\nfragment ConnectionsFragment on Query {\n  followers: followsCollection(filter: {followee_id: {eq: $id}}) {\n    totalCount\n  }\n  following: followsCollection(filter: {follower_id: {eq: $id}}) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc5b040697a13d3f71dfcf376a515fe2";

export default node;
