/**
 * @generated SignedSource<<4b63644e4e2e77f8740978a9e2401158>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LibraryTabContentPlaylistsQuery$variables = {
  identityIds?: ReadonlyArray<string> | null;
};
export type LibraryTabContentPlaylistsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LibraryTabContentPlaylistsPaginated">;
};
export type LibraryTabContentPlaylistsQuery = {
  response: LibraryTabContentPlaylistsQuery$data;
  variables: LibraryTabContentPlaylistsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "identityIds"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "in",
            "variableName": "identityIds"
          }
        ],
        "kind": "ObjectValue",
        "name": "identity_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LibraryTabContentPlaylistsQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "identityIds",
            "variableName": "identityIds"
          }
        ],
        "kind": "FragmentSpread",
        "name": "LibraryTabContentPlaylistsPaginated"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LibraryTabContentPlaylistsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "playlistsConnection",
        "kind": "LinkedField",
        "name": "playlistsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "playlistsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "playlists",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nodeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image_uri",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banner_uri",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "playlists_tokensConnection",
                    "kind": "LinkedField",
                    "name": "playlists_tokensCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "LibraryTabContentPlaylistsPaginated_playlistsCollection",
        "kind": "LinkedHandle",
        "name": "playlistsCollection"
      }
    ]
  },
  "params": {
    "cacheID": "c1a9916f4eb304be8d107874f6b23dee",
    "id": null,
    "metadata": {},
    "name": "LibraryTabContentPlaylistsQuery",
    "operationKind": "query",
    "text": "query LibraryTabContentPlaylistsQuery(\n  $identityIds: [BigInt!]\n) {\n  ...LibraryTabContentPlaylistsPaginated_4ExLJP\n}\n\nfragment LibraryTabContentPlaylistsPaginated_4ExLJP on Query {\n  playlistsCollection(filter: {identity_id: {in: $identityIds}}, first: 20) {\n    edges {\n      node {\n        id\n        nodeId\n        ...PlaylistCardDefaultFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment PlaylistCardDefaultFragment on playlists {\n  id\n  nodeId\n  title\n  image_uri\n  banner_uri\n  playlists_tokensCollection {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "fb53b84df9bab7478792e93df8878fc2";

export default node;
