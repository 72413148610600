import {fetchQuery, relay} from '@/kits/relay-kit/src';
import Profile from '@/view/pages/profile';
import {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from 'next';
import React from 'react';
import getProfileMetadataQuery from '@/graphql/get-profile-metadata-query';
import type {
  getProfileMetadataQuery as GetProfileMetadataQueryType,
  getProfileMetadataQuery$variables,
} from '@/graphql/__generated__/getProfileMetadataQuery.graphql';

const ProfilePage: NextPage<
  InferGetServerSidePropsType<typeof getServerSideProps>
> = props => {
  return <Profile props={props} />;
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const {id: rawId} = context.query;

  const id = typeof rawId === 'string' ? rawId : rawId?.[0] || '';

  const isTezosAddress = !!id.startsWith('tz') && id.length === 36;

  const variables: getProfileMetadataQuery$variables = {
    ...(Number.isInteger(Number(id))
      ? {
          identityId: id,
          skipAddress: true,
        }
      : isTezosAddress
      ? {
          address: id,
          skipAddress: false,
        }
      : {
          username: id,
          skipAddress: true,
        }),
  };

  console.log('variables', variables);

  const data = await fetchQuery<GetProfileMetadataQueryType>(
    relay,
    getProfileMetadataQuery,
    variables
  ).toPromise();

  const res = data?.profilesCollection?.edges?.[0]?.node;
  const beaconRes = data?.beacon_accountsCollection?.edges?.[0]?.node;
  const magicRes = data?.magic_accountsCollection?.edges?.[0]?.node;
  const teztokRes = data?.teztok_accountsCollection?.edges?.[0]?.node;

  if (isTezosAddress) {
    if (beaconRes) {
      return {
        props: {
          id: beaconRes.accounts.identity_id,
          title: id,
          description:
            beaconRes.accounts.identities.profilesCollection?.edges?.[0]?.node
              ?.description,
          image:
            beaconRes.accounts.identities.profilesCollection?.edges?.[0]?.node
              ?.image,
        },
      };
    } else if (magicRes) {
      return {
        props: {
          id: magicRes.accounts.identity_id,
          title: id,
          description:
            magicRes.accounts.identities.profilesCollection?.edges?.[0]?.node
              ?.description,
          image:
            magicRes.accounts.identities.profilesCollection?.edges?.[0]?.node
              ?.image,
        },
      };
    } else if (teztokRes) {
      return {
        props: {
          id: teztokRes.accounts.identity_id,
          title: id,
          description:
            teztokRes.accounts.identities.profilesCollection?.edges?.[0]?.node
              ?.description,
          image:
            teztokRes.accounts.identities.profilesCollection?.edges?.[0]?.node
              ?.image,
        },
      };
    }
  }

  if (res) {
    if (res.title !== id) {
      return {
        redirect: {
          permanent: false,
          destination: `/${res.title}`,
        },
      };
    }

    return {
      props: {
        id: res.identity_id,
        title: res.title,
        description: res.description,
        image: res.image,
      },
    };
  }

  return {
    props: {},
    notFound: true,
  };
}

export default ProfilePage;
