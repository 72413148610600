import React, {Suspense} from 'react';
import styles from '@/view/styles/components/ProfileHeader/blocs/ProfileActions/ProfileActions.module.scss';
import ProfileHeaderActionsUserDetails, {
  ProfileHeaderActionsUserDetailsSocials,
} from '@/view/components/ProfileHeader/blocs/ProfileActions/blocs/ProfileHeaderActionsUserDetails';
import ProfileHeaderActionsUsername from '@/view/components/ProfileHeader/blocs/ProfileActions/blocs/ProfileHeaderActionsUsername';
import {MenuOptionType} from '@/view/types/types';
import ProfileHeaderActionsFeaturedLinks from './blocs/ProfileHeaderActionsFeaturedLinks';
import FollowButton from '@/view/components/FollowButton';
import Button from '@/view/components/Button';
import RemixIcon from '@/view/components/RemixIcon';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import Router from 'next/router';
import Menu from '@/view/components/Menu';
import Skeleton from '@/view/components/Skeleton';
import {SendMoneyDialog} from '@/view/components/SendMoney/SendMoneyDialog';
import {useAccount} from '@/kits/account-kit/src';
import {MESSAGING} from '@/config';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from '@/kits/relay-kit/src';
import insertGroupMutation from '@/graphql/insert-group';
import type {insertGroupMutation as InsertGroupMutationType} from '@/graphql/__generated__/insertGroupMutation.graphql';
import insertAccountsInGroupMutation from '@/graphql/insert-accounts-in-group';
import type {insertAccountsInGroupMutation as InsertAccountsInGroupMutationType} from '@/graphql/__generated__/insertAccountsInGroupMutation.graphql';
import {ToastActions} from '@/state/hooks/toasts';
import {ProfileActionsFragment$key} from './__generated__/ProfileActionsFragment.graphql';
import type {ProfileActionsGetDmQuery as ProfileActionsGetDmQueryType} from './__generated__/ProfileActionsGetDmQuery.graphql';

const ProfileActionsFragment = graphql`
  fragment ProfileActionsFragment on profiles {
    id
    nodeId
    identities {
      accountsCollection {
        edges {
          node {
            id
            nodeId
            type
            magic_accountsCollection {
              edges {
                node {
                  id
                  nodeId
                  public_key_hash
                }
              }
            }
            beacon_accountsCollection {
              edges {
                node {
                  id
                  nodeId
                  public_key_hash
                }
              }
            }
            teztok_accountsCollection {
              edges {
                node {
                  id
                  nodeId
                  public_key_hash
                }
              }
            }
          }
        }
      }
    }
    website
    ...ProfileHeaderActionsUsernameFragment
    ...ProfileHeaderActionsUserDetailsFragment
  }
`;

export default function ProfileActions({
  id,
  isOwnProfile,
  userProfileKey,
  loggedIn,
  options,
}: {
  id: string;
  isOwnProfile: boolean;
  userProfileKey: ProfileActionsFragment$key | undefined | null;
  loggedIn: boolean;
  options: MenuOptionType[];
}) {
  const {getAccount} = useAccount();
  const account = getAccount();
  const accountId = account.isLoggedIn ? account.accountId : null;

  const {isMobile} = useBreakpoint();

  const userProfile = useFragment(ProfileActionsFragment, userProfileKey);

  const userAddress =
    userProfile?.identities?.accountsCollection?.edges?.[0]?.node?.type ===
    'magic'
      ? userProfile?.identities?.accountsCollection?.edges?.[0]?.node
          ?.magic_accountsCollection?.edges?.[0]?.node?.public_key_hash
      : userProfile?.identities?.accountsCollection?.edges?.[0]?.node?.type ===
        'beacon'
      ? userProfile?.identities?.accountsCollection?.edges?.[0]?.node
          ?.beacon_accountsCollection?.edges?.[0]?.node?.public_key_hash
      : userProfile?.identities?.accountsCollection?.edges?.[0]?.node?.type ===
        'teztok'
      ? userProfile?.identities?.accountsCollection?.edges?.[0]?.node
          ?.teztok_accountsCollection?.edges?.[0]?.node?.public_key_hash
      : undefined;

  return (
    <>
      <div className={styles.details}>
        <div className={styles.left}>
          <ProfileHeaderActionsUsername userProfileKey={userProfile} />
          <ProfileHeaderActionsUserDetails userProfileKey={userProfile} />
        </div>
        <div className={styles.right} data-is-own-profile={isOwnProfile}>
          {!isOwnProfile && <FollowButton id={id} />}
          {/* {!isMobile && !isOwnProfile && loggedIn && (
            <SendMoneyDialog
              userAddress={userAddress}
              trigger={
                <Button gradient tooltip="Send Tezos">
                  <RemixIcon icon="money-dollar-circle-line" size={16} />
                  <span>Send Money</span>
                </Button>
              }
            />
          )} */}
          {!isMobile && MESSAGING && accountId && !isOwnProfile && (
            <Suspense
              fallback={
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={32}
                  width={90}
                  borderRadius={99}
                />
              }
            >
              <SendMessageButton
                userAccountId={
                  userProfile?.identities?.accountsCollection?.edges?.[0]?.node
                    ?.id
                }
              />
            </Suspense>
          )}
          {loggedIn &&
            (isOwnProfile ? (
              <Button
                outlined={!isMobile}
                icon={isMobile}
                onClick={() => {
                  Router.push('/settings');
                }}
                className={styles.edit}
              >
                <RemixIcon icon="edit-2-fill" size={22} />
                {!isMobile && <span>Edit Profile</span>}
              </Button>
            ) : (
              options.filter(o => !o.disabled).length > 0 && (
                <Button icon key="menu">
                  <Menu
                    options={options}
                    offset={24}
                    arrow={false}
                    side="right"
                  >
                    <span>
                      <RemixIcon icon="more-line" size={22} />
                    </span>
                  </Menu>
                </Button>
              )
            ))}
        </div>
      </div>
      {isMobile && !isOwnProfile && (
        <div className={styles.mobileActions}>
          {/* {loggedIn && (
            <SendMoneyDialog
              userAddress={userAddress}
              trigger={
                <Button outlined accent tooltip="Send Tezos">
                  <RemixIcon icon="money-dollar-circle-line" size={16} />
                  <span>Send Money</span>
                </Button>
              }
            />
          )} */}
          {MESSAGING && accountId && (
            <Suspense
              fallback={
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={32}
                  width={90}
                  borderRadius={99}
                />
              }
            >
              <SendMessageButton
                userAccountId={
                  userProfile?.identities?.accountsCollection?.edges?.[0]?.node
                    ?.id
                }
              />
            </Suspense>
          )}
        </div>
      )}
      <div className={styles.statsAndSocials}>
        <ProfileHeaderActionsUserDetailsSocials userId={id} />
        <div>
          <ProfileHeaderActionsFeaturedLinks website={userProfile?.website} />
        </div>
      </div>
    </>
  );
}

const ProfileActionsGetDmQuery = graphql`
  query ProfileActionsGetDmQuery($u1: Int!, $u2: Int!) {
    get_personal_chat_group(user_1: $u1, user_2: $u2)
  }
`;

const SendMessageButton = ({userAccountId}: {userAccountId?: string}) => {
  const {getAccount} = useAccount();
  const account = getAccount();
  const accountId = account.isLoggedIn ? account.accountId : null;

  const {data} = useLazyLoadQuery<ProfileActionsGetDmQueryType>(
    ProfileActionsGetDmQuery,
    {
      u1: parseInt(accountId || ''),
      u2: parseInt(userAccountId || ''),
    },
    {
      skip:
        !accountId ||
        accountId === '' ||
        !userAccountId ||
        userAccountId === '' ||
        accountId === userAccountId,
    }
  );

  const [insertGroup] =
    useMutation<InsertGroupMutationType>(insertGroupMutation);
  const [insertAccountsInGroup] =
    useMutation<InsertAccountsInGroupMutationType>(
      insertAccountsInGroupMutation
    );

  const [loading, setLoading] = React.useState(false);

  const createGroupAndAddAccounts = () => {
    if (!accountId || !userAccountId) return;
    setLoading(true);
    insertGroup({
      variables: {
        input: [
          {
            created_at: 'now',
            updated_at: 'now',
            is_private: true,
          },
        ],
      },
      onCompleted(response) {
        const group_id = response.insertIntogroupsCollection?.records?.[0]?.id;
        insertAccountsInGroup({
          variables: {
            input: [
              {
                group_id,
                account_id: userAccountId,
                read: true,
                write: true,
                execute: true, // NOTE: this is a personal chat, both members get the execute permission
              },
              {
                group_id,
                account_id: accountId,
                read: true,
                write: true,
                execute: true,
              },
            ],
          },
          onCompleted() {
            setLoading(false);
            if (!group_id) return;
            Router.push(`/inbox/${group_id}`);
          },
          onError() {
            ToastActions.addToast(
              `error-${accountId}-${userAccountId}`,
              'Something went wrong, please try again later.',
              'Failed to create a conversation with this user.',
              'failure'
            );
            setLoading(false);
          },
        });
      },
      onError() {
        ToastActions.addToast(
          `error-${accountId}-${userAccountId}`,
          'Something went wrong, please try again later.',
          'Failed to create a conversation with this user.',
          'failure'
        );
        setLoading(false);
      },
    });
  };

  const dmGroupId = data?.get_personal_chat_group;

  const {isMobile} = useBreakpoint();

  return dmGroupId ? (
    <Button
      gradient={!isMobile}
      outlined={isMobile}
      accent={isMobile}
      onClick={() => {
        Router.push(`/inbox/${dmGroupId}`);
      }}
    >
      <RemixIcon icon="chat-3-line" size={18} />
      <span>Message</span>
    </Button>
  ) : (
    <Button
      gradient={!isMobile}
      outlined={isMobile}
      accent={isMobile}
      onClick={() => {
        createGroupAndAddAccounts();
      }}
      disabled={loading}
    >
      {loading ? (
        <RemixIcon icon="loader-4-line" size={18} className={styles.spinner} />
      ) : (
        <RemixIcon icon="chat-3-line" size={18} />
      )}
      <span>{loading ? 'Please wait' : 'Message'}</span>
    </Button>
  );
};
